<template>
  <div class="sms-table">
    <v-card class="pb-1">
      <v-card-title>
        <v-container>
          <v-row>
            <v-col class="col-md-2">
              Sms
            </v-col>
            <v-col class="col-md-1">
              <refresh-button
                :loading="isSmsRefreshing"
                @click="refreshSms()"
              />
            </v-col>
            <v-col class="col-md-6">
              <table-filter
                :table-name="'sms'"
                :show-sms-status-filter="true"
                :default-date-picker-date="defaultDatePickerDate"
                @filter="applyFilters"
              />
            </v-col>
            <v-col class="col-md-3">
              <search-input v-model="search" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center col-md-12">
              <span class="font-weight-light">Total Cost: </span>
              <span v-show="!isSmsLoading">Ksh {{ smsCost | formatCurrencyWithDecimal }}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="sms"
        :expanded.sync="expanded"
        :loading="isSmsLoading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:progress>
          <v-progress-linear
            indeterminate
            class="mt-2"
            rounded
          ></v-progress-linear>
          <p class="text-center mt-1">
            Loading...Please wait
          </p>
        </template>
        <template v-slot:body="{ items }">
          <tbody v-if="sms.length > 0">
            <tr
              v-for="(single_sms, index) in items"
              :key="index"
              class="clickable"
              @click="showMessage(single_sms.message)"
            >
              <td>
                <span class="primary--text font-weight-medium">{{ single_sms.user.account_number }}</span>
              </td>
              <td>
                <span class="primary--text font-weight-medium">{{ single_sms.user.name }}</span>
              </td>
              <td>{{ single_sms.phone }}</td>
              <td class="truncate">
                {{ single_sms.message }}
              </td>
              <td>Ksh {{ single_sms.cost }}</td>
              <td>
                <v-chip
                  text-color="white"
                  class="my-2"
                  :class="{
                    delivered: single_sms.status === 'Delivered',
                    sent: single_sms.status === 'Sent',
                    failed: single_sms.status === 'Failed',
                    queue: single_sms.status === 'Queue',
                    'insufficient-balance': single_sms.status === 'InsufficientBalance',
                  }"
                >
                  {{ single_sms.status }}
                </v-chip>
                <p v-if="single_sms.status === 'Failed'">
                  {{ single_sms.failure_reason }}
                </p>
              </td>
              <td>{{ single_sms.created_at | formatDate }}</td>
              <td @click.stop>
                <v-btn
                  small
                  depressed
                  color="primary"
                  @click="showResendSmsDialog(single_sms.id, single_sms.user.name)"
                >
                  <v-icon
                    left
                    size="16"
                  >
                    {{ icons.mdiSend }}
                  </v-icon>
                  Resend
                </v-btn>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-show="!isSmsLoading">
              <td
                :colspan="headers.length"
                style="text-align: center"
              >
                No SMS found
              </td>
            </tr>
          </tbody>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            {{ item.message }}
          </td>
        </template>
      </v-data-table>
      <v-divider />
      <data-table-pagination
        :page="pagination.page"
        :page-count="pagination.pageCount"
        @page-change="onPageChange"
        @items-per-page-change="onItemsPerPageChange"
      />
      <v-dialog
        v-model="showMessageDialog"
        max-width="450px"
      >
        <v-card>
          <v-card-text>
            <p
              style="white-space: pre-line"
              class="pt-4"
            >
              {{ message }}
            </p>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
    <confirm-dialog
      :show-dialog="showConfirmResendDialog"
      :is-agree-button-loading="isConfirmResendDialogButtonLoading"
      :agree-text="'Resend'"
      :message="resendSmsMessage"
      @cancel="showConfirmResendDialog = false"
      @agree="resendSms()"
    />
    <send-sms-dialog
      :show-dialog="sendSmsDialog"
      @close="$emit('close')"
      @sent="onSmsSent()"
    />
  </div>
</template>
<script>
import axios from 'axios'
import _ from 'lodash'
import { mdiSend } from '@mdi/js'
import SendSmsDialog from '@/components/dialogs/SendSmsDialog.vue'
import SearchInput from '@/components/partials/SearchInput.vue'
import TableFilter from '../partials/table-filter/index.vue'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import DataTablePagination from '@/components/partials/DataTablePagination.vue'

export default {
  components: {
    SendSmsDialog,
    SearchInput,
    TableFilter,
    RefreshButton,
    DataTablePagination,
    ConfirmDialog,
  },
  props: {
    smsUrl: {
      type: String,
      default: null,
    },
    clickableRows: {
      type: Boolean,
      default: true,
    },
    sendSmsDialog: {
      type: Boolean,
      default: true,
    },
    forSpecificCustomer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortBy: ['created_at'],
      sortDesc: [true],
      search: '',
      headers: [
        {
          text: 'Account number',
          value: 'account_number',
          sortable: false,
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Phone number',
          value: 'phone',
          sortable: false,
        },
        {
          text: 'Message',
          value: 'message',
          width: '40%',
        },
        { text: 'Cost', value: 'cost' },
        { text: 'Status', value: 'status' },
        { text: 'Sent at', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      expanded: [],
      singleExpand: false,
      isSmsLoading: true,
      isSmsRefreshing: false,
      isConfirmResendDialogButtonLoading: false,
      showMessageDialog: false,
      showConfirmResendDialog: false,
      sms: [],
      smsCost: '',
      message: '',
      pagination: {
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
      },
      filters: {
        dates: {
          fromDate: '',
          toDate: '',
        },
        smsStatus: [],
      },
      icons: {
        mdiSend,
      },
      defaultDatePickerDate: '',
      toResendCustomerName: '',
    }
  },
  computed: {
    sortOrder() {
      return this.sortDesc[0] ? 'desc' : 'asc'
    },
    refreshTable() {
      return `${this.smsUrl}|${this.search}|${this.sortBy}|${this.sortDesc}`
    },
    resendSmsMessage() {
      return `Are you sure you want to resend this sms to ${this.toResendCustomerName}?`
    },
  },
  watch: {
    refreshTable() {
      this.getSms()
    },
  },
  mounted() {
    if (this.forSpecificCustomer) {
      this.defaultDatePickerDate = 'Last 1 year'
    } else {
      this.defaultDatePickerDate = 'Last 30 days'
    }
    this.getSms()
  },
  methods: {
    getSms: _.debounce(function () {
      if (this.sortBy.length !== 0) {
        this.isSmsLoading = true
        const status = JSON.stringify(this.filters.smsStatus)
        axios
          .get(
            `${this.smsUrl}&fromDate=${this.filters.dates.fromDate}&toDate=${this.filters.dates.toDate}&sortBy=${this.sortBy[0]}&sortOrder=${this.sortOrder}&status=${status}&search=${this.search}&page=${this.pagination.page}&perPage=${this.pagination.itemsPerPage}`,
          )
          .then(response => {
            this.smsCost = response.data.sms_cost
            this.sms = response.data.sms.data
            this.pagination.page = response.data.sms.current_page
            this.pagination.pageCount = response.data.sms.last_page
            this.pagination.itemsPerPage = response.data.sms.per_page
            this.isSmsLoading = false
            this.isSmsRefreshing = false
          })
          .catch(error => {
            this.isSmsLoading = false
            this.isSmsRefreshing = false
            this.$notification.error(error.response.data.message)
          })
      }
    }, 500),
    resendSms() {
      this.isConfirmResendDialogButtonLoading = true
      axios
        .post(`sms-resend/${this.toResend}`)
        .then(() => {
          this.$notification.success(`Sms has been resend to ${this.toResendCustomerName}`)
          this.isConfirmResendDialogButtonLoading = false
          this.showConfirmResendDialog = false
          this.onSmsSent()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
          this.isConfirmResendDialogButtonLoading = false
          this.showConfirmResendDialog = false
        })
    },
    showResendSmsDialog(id, name) {
      this.showConfirmResendDialog = true
      this.toResend = id
      this.toResendCustomerName = name
    },
    refreshSms() {
      this.isSmsRefreshing = true
      this.getSms()
    },
    onPageChange(page) {
      this.pagination.page = page
      this.getSms()
    },
    onItemsPerPageChange(itemsPerPage) {
      this.pagination.itemsPerPage = itemsPerPage
      this.getSms()
    },
    showMessage(message) {
      this.message = message
      this.showMessageDialog = true
    },
    onSmsSent() {
      this.getSms()
      this.$emit('sms-sent')
      this.$emit('close')
    },
    applyFilters(filters) {
      this.filters = filters
      this.getSms()
    },
  },
}
</script>

<style scoped>
.truncate {
  max-width: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.clickable {
  cursor: pointer;
}
.v-chip.failed {
  background: #f44336;
}
.v-chip.delivered {
  background: #4caf50;
}
.v-chip.queued,
.v-chip.insufficient-balance {
  background: #ffa726;
}
.v-chip.sent {
  background: #2196f3;
}
</style>
